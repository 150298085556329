<template>
  <div class="index_container">
    <el-container>
      <el-main>
        <!-- 头部 -->
        <PageHeader></PageHeader>
        <!-- 中间动效部分 -->
        <div class="index_main_center">
          <div class="center_nav_item" v-for="(item,index) in centerNavs" :key="index" @mouseenter="mouseEnter(item)"
            @click="toDetailsPage(item)" :class="activeItem==item.id?'activeNav':''">
            <dl>
              <dd>
                <img :src="item.icon" alt="">
                <div class="text_box">
                  <div class="text">{{item.text}}</div>
                </div>
              </dd>
              <dt>
                <span>{{item.title}}</span>
              </dt>
            </dl>
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <BottomFooter></BottomFooter>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  import PageHeader from '../components/PageHeader'
  export default {
    name: 'WorkspaceJsonIndex',
    components: {
      BottomFooter,
      PageHeader
    },

    data() {
      return {
        menus: [{
            id: 1,
            menu_title: 'LD前台'
          },
          {
            id: 2,
            menu_title: 'LD学员'
          },
          {
            id: 3,
            menu_title: 'LD教员'
          },
          {
            id: 4,
            menu_title: 'LDBoss'
          }
        ],
        centerNavs: [
          {
            id: 0,
            title: '减分识题',
            icon: require('.././assets/logo.png'),
            text: '一秒一题、精选题库、浮窗操作、免费试用...'
          },{
          id: 1,
          title: 'LD前台',
          icon: require('.././assets/logo.png'),
          text: '学员管理、财务管理、考试管理、后台管理...'
        }, {
          id: 2,
          title: 'LD学员',
          icon: require('.././assets/logo.png'),
          text: '科目一、二、三、四学车、预约、个人信息、教学评价...'
        }, {
          id: 3,
          title: 'LD教员',
          icon: require('.././assets/logo.png'),
          text: '练车考试计划、接送管理、代办成绩考核录入、财务报销...'
        }, {
          id: 4,
          title: '申请入驻',
          icon: require('.././assets/logo.png'),
          text: '半年免费试用、旧数据一键导入、节省更多成本、每月分佣...'
        }],
        activeItem: 0,

      };
    },

    mounted() {

    },

    methods: {
      // 鼠标经过中间菜单
      mouseEnter(item) {
        this.activeItem = item.id
      },
      //   点击中间菜单
      toDetailsPage(item) {
        if (item.id == 0) {
          this.$router.push('/recognize')
        }else if (item.id == 1) {
          this.$router.push('/front')
        } else if (item.id == 2) {
          this.$router.push('/learner')
        } else if (item.id == 3) {
          this.$router.push('/coach')
        } else if (item.id == 4) {
          this.$router.push('/joinLD')
        }
      }

    },
  };

</script>

<style lang="scss" scoped>
  .index_container {
    background: url("https://ledong-1305301454.cos.ap-guangzhou.myqcloud.com/%E5%87%8F%E5%88%86%E5%AE%98%E7%BD%91%E8%83%8C%E6%99%AF222.001.jpeg") no-repeat center center / auto 100% rgb(245, 245, 245);
    ;
    width: 100%;
    min-width: 960px;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;

    .el-container {
      height: 100%;

      .el-main {

        .index_main_header {
          height: 130px;
          width: 100%;
          box-sizing: border-box;
          padding: 30px 50px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .logo {
            width: 350px;
            height: 100px;
            display: flex;
            align-items: center;

            .pic {
              width: 100px;
              height: 100px;
            }

            .title {
              margin-left: 40px;
              font-size: 35px;
              font-weight: 600;
              color: #ffffff;
            }
          }

          .menus {
            width: 500px;
            display: flex;
            font-size: 22px;
            color: #fff;
            justify-content: flex-end;
            box-sizing: border-box;
            padding-top: 19px;

            .menus_item {
              margin-left: 20px;
              height: 23px;
              line-height: 23px;
              cursor: pointer;
            }
          }
        }

        .index_main_center {
          margin-top: 100px;
          width: 100%;
          flex: 1 1 0%;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;


          .center_nav_item {
            display: block;
            text-decoration: none;
            margin: 0px 1em;
            overflow: hidden;
            border-radius: 6px;
            opacity: 0.5;
            transition: transform 500ms ease 0s, opacity 500ms ease 0s;
            background: #333333;
            cursor: pointer;

            dl {
              display: flex;
              color: rgb(255, 255, 255);
              height: 300px;
              margin: 0px;

              dd {
                box-sizing: border-box;
                position: relative;
                width: 0px;
                height: 100%;
                overflow: hidden;
                margin: 0px;
                transition: width 500ms ease 0s;

                img {
                  display: block;
                  width: 130px;
                  height: 130px;
                  top: 22px;
                  left: 22px;
                  margin: auto;
                  position: absolute;
                  opacity: 1;
                }

                .text_box {
                  width: 400px;
                  height: 100%;
                  position: relative;
                  box-sizing: border-box;
                  padding: 20px;

                  .text {
                    position: absolute;
                    width: 260px;
                    bottom: 15px;
                  }
                }
              }

              dt {
                box-sizing: border-box;
                margin: 0px;
                padding: 1em;
                width: 100px;
                height: 100%;
                display: flex;
                -webkit-writing-mode: vertical-rl;
                writing-mode: vertical-rl;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                font-size: 22px;
                letter-spacing: 0.5em;
                font-weight: bold;
                white-space: nowrap;
              }
            }
          }

          .activeNav {
            opacity: 1;

            dd {
              width: 300px !important;

              img {
                width: 130px !important;
                height: 130px !important;
                z-index: 9999;
              }
            }
          }


          //   .center_nav_item {
          //     width: 100px;
          //     height: 300px;
          //     background-color: #333333;
          //     box-sizing: border-box;
          //     border: 1px solid #ffffff;
          //     border-radius: 10px;
          //     opacity: .8;
          //     margin: 0 1em;
          //     cursor: pointer;
          //     display: flex;
          //     align-items: center;
          //     color: #fff;
          //     transition: transform 500ms ease 0s, opacity 500ms ease 0s;

          //     .item_top {
          //       box-sizing: border-box;
          //       margin: 0px;
          //       padding: 0 1em;
          //       width: 100px;
          //       height: 200px;
          //       display: flex;
          //       -webkit-box-align: center;
          //       align-items: center;
          //       -webkit-box-pack: center;
          //       justify-content: center;
          //       font-size: 22px;
          //       letter-spacing: 0.7em;
          //       font-weight: bold;
          //       white-space: nowrap;
          //       transition: width 500ms ease 0s;

          //       .title_text {
          //         box-sizing: border-box;
          //         padding-top: 30px;
          //         display: block;
          //         writing-mode: vertical-rl
          //       }

          //       img {
          //         display: none;
          //       }
          //     }

          //     .item_bottom {
          //       display: none;
          //     }
          //   }

          //   .activeNav {
          //     width: 300px;
          //     box-sizing: border-box;
          //     padding: 10px 20px 0 20px;
          //     display: flex;
          //     flex-direction: column;
          //     opacity: 1;
          //     transition: transform 500ms ease 0s, opacity 500ms ease 0s;

          //     .item_top {
          //       width: 300px;
          //       box-sizing: border-box;
          //       display: flex;
          //       flex-direction: row;
          //       justify-content: space-around;
          //       transition: width 500ms ease 0s;

          //       .pic {
          //         width: 170px;
          //         display: flex;
          //         justify-content: flex-end;


          //         img {
          //           width: 130px;
          //           height: 130px;
          //           display: block;
          //         }
          //       }

          //     }

          //     .item_bottom {
          //       width: 250px;
          //       display: block;
          //       transition: width 500ms ease 0s;
          //     }
          //   }
        }

      }

      .el-footer {
        height: 100px !important;
        background-color: #fff;

        .index_footer {
          width: 90%;
          height: 100%;
          margin: 0px auto;
          display: flex;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left_box {
            width: 350px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .left_item {
              width: 90px;
              cursor: pointer;
              margin-bottom: 6px;
            }

          }
        }
      }
    }
  }

</style>
